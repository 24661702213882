<!-- SuccessIcon.vue -->
<template>
    <div class="icon-wrapper">
        <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <!-- Background circle -->
            <circle cx="50" cy="50" r="45" :fill="color" />

            <!-- White checkmark -->
            <path d="M30 50 L45 65 L70 35" stroke="white" stroke-width="8" stroke-linecap="round"
                stroke-linejoin="round" fill="none" />

            <!-- Outer ring animation -->
            <circle cx="50" cy="50" r="48" fill="none" :stroke="color" stroke-width="2" opacity="0.5">
                <animate attributeName="r" from="48" to="52" dur="1.5s" repeatCount="indefinite" />
                <animate attributeName="opacity" from="0.5" to="0" dur="1.5s" repeatCount="indefinite" />
            </circle>
        </svg>
    </div>
</template>

<script>
export default {
    name: 'SuccessIcon',
    props: {
        color: {
            type: String,
            default: '#4CAF50'
        },
        size: {
            type: [Number, String],
            default: 24
        }
    }
}
</script>

<style scoped>
svg {
    width: v-bind(size + 'px');
    height: v-bind(size + 'px');
}

.icon-wrapper {
    padding: 4px;
}
</style>