<template>
    <PopupModal :isShow="isShow">
        <template slot="body">
            <div class="qris-modal">
                <div class="icon-container">
                    <img src="@/assets/images/close-circle.svg" alt="" class="close-icon" @click="handleCloseModal" />

                </div>
                <div class="qris-logo-container">
                    <img src="../../../../assets/images/qris.png" class="qris-logo" />
                </div>
                <div v-if="!isQrPaymentSuccess" class="qris-modal-body">
                    <canvas ref="qrCanvas"></canvas>
                </div>
                <div v-else class="qris-modal-body-success">
                    <SuccessIcon :size="94" />
                    <p class="text-success">Success payment.</p>
                    <p>Close in {{ timer }} seconds.</p>
                </div>
                <div class="button-simulate" @click="handleSimulatePayment">
                    <div class="">
                        <p class="text-white text-center">Simulate payment. {{ qrTransactionId }}</p>
                    </div>
                </div>
            </div>
        </template>
    </PopupModal>
</template>

<script>
import PopupModal from '@/components/PopupModal.vue';
import axios from 'axios';
import QRCode from 'qrcode'
import SuccessIcon from '../common/SuccessIcon.vue';

export default {
    name: 'QrisModal',
    components: {PopupModal, SuccessIcon},
    props: {
        isShow: {
            type: Boolean,
            required: true
        },
        size: {
            type: Number,
            default: 200
        },
        qrisString: {
            type: String,
            required: true,
        },
        qrTransactionId: {
            type: String,
            required: true
        },
        isQrPaymentSuccess: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            timer: 5
        }
    },
    methods: {
        handleCloseModal() {
            this.$emit('close')
            this.timer = 5;
        },
        generateQR() {
            const canvas = this.$refs.qrCanvas;
            if(!canvas) return;

            if(this.qrisString) {
                QRCode.toCanvas(canvas, this.qrisString, {
                    width: this.size,
                    margin: 2,
                    errorCorrectionLevel: 'H',
                    color: {
                        dark: '#000000',
                        light: '#ffffff'
                    }
                }).catch(error => {
                    console.error('Error generating QR code:', error);
                    this.drawErrorMessage(canvas);
                });
            } else {
                this.drawErrorMessage(canvas);
            }
        },
        drawErrorMessage(canvas) {
            const ctx = canvas.getContext('2d');
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            canvas.width = this.size;
            canvas.height = this.size;

            ctx.fillStyle = '#000000';
            ctx.font = '16px Arial';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';

            ctx.fillText('No QR String', canvas.width / 2, canvas.height / 2);
        },
        async handleSimulatePayment() {
            try {
                const payload = {
                    order_id: this.qrTransactionId
                }
                const {data} = await axios.post(`https://dev-api.nvx.co.id/api/v1/qr/simulate-payment`, payload)

                console.log(data, "SIMULATE PAYMENT")
                this.isSuccessPayment = true;
            } catch(err) {
                console.error(err)
            }
        },
        startCountdown() {
            const countdown = setInterval(() => {
                this.timer--
            }, 1000)

            setTimeout(() => {
                clearInterval(countdown)
                this.handleCloseModal()
            }, 5000)
        }
    },
    watch: {
        isShow(newVal) {
            if(newVal) {
                this.generateQR();
            }
        },
        isQrPaymentSuccess(newVal) {
            if (newVal) {
                this.startCountdown();
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.qris-modal {
    max-width: 448px;
    height: auto;
    margin: 0 auto;
    background: white;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
}

.icon-container {
    display: flex;
    justify-content: end;
    padding: 8px;
}

img.close-icon {
    cursor: pointer;
}

.qris-logo-container {
    display: flex;
    justify-content: center;
}

.qris-logo {
    width: 96px;
    height: auto;
}

.qris-modal-body {
    padding: 20px;
    display: flex;
    justify-content: center;
}

.qris-modal-body-success {
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

canvas {
    max-width: 100%;
    height: auto;
}

.button-simulate {
    background-color: rgb(51, 122, 183);
    padding: 12px;
    cursor: pointer;
    font-size: 16px;
}

.button-simulate:hover {
    background-color: rgb(41, 98, 150);
}

.text-success {
    font-size: 24px;
}
</style>