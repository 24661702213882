<template>
  <div class="container_deposit_fiat" id="funds_deposit_usd">
    <div class="title title-medium-small">
      {{ $t('menu.deposit_usd') }}
    </div>

    <div class="deposit_fiat">
      <div class="left_deposit_fiat table-container">
        <div class="list-balance">
          <div class="content-item-balance">
            <p class="item-balance" style="margin-bottom: 0px">
              <span>{{ $t('funds.withdrawals.total_balance') }}</span>
              <strong class="text-right"
                >{{
                  balance.balance
                    | splitAmount(currencyName)
                    | formatCurrencyAmount(currencyName, '0')
                }}
                IDR</strong
              >
            </p>
            <div class="text-center" v-if="!isShowDetails">
              <button
                @click="isShowDetails = true"
                style="background-color: #f8f8f8; margin: 8px 7px 0 0"
              >
                {{ $t('funds.balances.show_details') }}
              </button>
              <img src="/images/arrow-down-lite.svg" alt="" />
            </div>
          </div>
          <div class="content-item-balance" v-if="isShowDetails">
            <p class="item-balance item-locked-balance">
              <span class="tooltip-container"
                >{{ $t('funds.balances.locked_balance') }}
                <div class="item group-tooltip">
                  <span class="help-box">
                    <img src="/images/icon/Question.png" alt="" />
                    <div class="box-tooltip">
                      <div class="bubble top locked-balance-text">
                        {{ $t('funds.balances.locked_balance_desc') }}
                      </div>
                    </div>
                  </span>
                </div>
              </span>
            </p>
            <p class="item-balance" style="margin: 8px 0">
              <span>{{ $t('funds.balances.in_transaction') }}</span>
              <strong class="text-right"
                >{{
                  balance.in_order
                    | splitAmount(currencyName)
                    | formatCurrencyAmount(currencyName, '0')
                }}
                IDR</strong
              >
            </p>
            <p class="item-balance" style="margin-bottom: 8px">
              <span>{{ $t('funds.balances.pending_withdrawal') }}</span>
              <strong class="text-right"
                >{{
                  balance.pending_withdrawal
                    | splitAmount(currencyName)
                    | formatCurrencyAmount(currencyName, '0')
                }}
                IDR</strong
              >
            </p>
            <p class="item-balance">
              <span>{{ $t('funds.balances.tradable_balance') }}</span>
              <strong class="text-right"
                >{{
                  balance.tradable_balance
                    | splitAmount(currencyName)
                    | formatCurrencyAmount(currencyName, '0')
                }}
                IDR</strong
              >
            </p>
            <hr style="border-top: 2.5px solid #ddd; margin: 8px 0" />
            <p class="item-balance" style="margin-bottom: 0px">
              <span>{{ $t('funds.balances.total_locked_balance') }}</span>
              <strong class="text-right"
                >{{
                  balance.total_locked_balance
                    | splitAmount(currencyName)
                    | formatCurrencyAmount(currencyName, '0')
                }}
                IDR</strong
              >
            </p>
          </div>
          <div class="content-item-balance" v-if="isShowDetails">
            <p class="item-balance" style="margin-bottom: 0px">
              <span>{{ $t('funds.withdrawals.available_balance') }}</span>
              <strong class="text-right"
                >{{
                  balance.available_balance
                    | splitAmount(currencyName)
                    | formatCurrencyAmount(currencyName, '0')
                }}
                IDR</strong
              >
            </p>
          </div>
          <div class="text-center" v-if="isShowDetails">
            <button
              @click="isShowDetails = false"
              style="background-color: #ffffff; margin-right: 7px"
            >
              {{ $t('funds.balances.hide_details') }}
            </button>
            <img src="/images/arrow-up-lite.svg" alt="" />
          </div>
        </div>
        <div v-if="isLoaded && isDepositEnable" class="box-left-group">
          <div class="box_left2">
            <div class="box_left2-content">
              <template v-if="hasPendingTransaction">
                <div class="left-content-result">
                  <p class="first_deposit_table">
                    {{ $t('funds.deposit_fiat.deposit_note') }}
                  </p>
                  <table class="pending_deposit_transaction_table">
                    <tr class="table_row">
                      <td class="title_row">
                        {{ $t('funds.deposit_fiat.pending_transaction.amount') }}
                      </td>
                      <td class="content_row">
                        {{ pendingTransaction.amount || 0 | formatNaturalPart }}
                        IDR
                      </td>
                    </tr>
                    <tr class="table_row">
                      <td class="title_row">
                        {{ $t('deposit_fiat.destination_bank') }}
                      </td>
                      <td class="content_row">
                        {{ bankCodeToName(pendingTransaction.bank) }}
                      </td>
                    </tr>
                    <tr class="table_row">
                      <td class="title_row">
                        {{ $t('deposit_fiat.account_name') }}
                      </td>
                      <td class="content_row">PT ASET KRIPTO INTERNASIONAL</td>
                    </tr>
                    <tr class="table_row" v-if="pendingTransaction.va_number">
                      <td class="title_row">
                        {{ $t('deposit_fiat.va_number') }}
                      </td>
                      <td class="content_row">
                        {{ pendingTransaction.va_number }}
                      </td>
                    </tr>
                    <!-- <tr class="table_row">
                      <td class="title_row">Bill key - Biller code:</td>
                      <td class="content_row">
                        {{ pendingTransaction.bill_key }} - {{ pendingTransaction.biller_code}}
                      </td>
                    </tr> -->
                    <!-- <tr class="table_row">
                      <td class="title_row">{{ $t('funds.deposit_fiat.pending_transaction.code') }}</td>
                      <td class="content_row color_code">
                        {{ pendingTransaction.code }}
                      </td>
                    </tr> -->
                  </table>

                  <div class="flex_button">
                    <div class="flex_button_box">
                      <button
                        type="submit"
                        class="form-control btn btn-submit w_100"
                        @click="createOtherDeposit()"
                      >
                        {{ $t('funds.deposit_fiat.other_deposit') }}
                      </button>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="left-content-submit">
                  <div class="address_for" for="address">
                    {{ $t('funds.deposit_fiat.deposit_amount') }}
                  </div>
                  <div class="address-box input-group">
                    <div>
                      <currency-input
                        :precision="0"
                        v-model="amount"
                        :class="{ error: errors.has('amount') }"
                        class="form-input"
                        @focus="resetError"
                      />
                      <span v-show="errors.has('amount')" class="invalid-feedback">{{
                        $t(errors.first('amount'))
                      }}</span>
                    </div>
                    <template v-if="isLoading">
                      <div class="flex_button_box">
                        <button
                          :disabled="isLoading"
                          type="submit"
                          class="btn btn-submit btn-isLoading deposit__submit-btn"
                        >
                          {{ $t('funds.withdrawals.loading') }}
                        </button>
                      </div>
                    </template>
                    <template v-else>
                      <div class="flex_button_box">
                        <button
                          :disabled="fetch"
                          type="submit"
                          class="btn btn-submit deposit__submit-btn"
                          @click="deposit()"
                        >
                          {{ fetch ? $t('funds.withdrawals.withdrawal_placeholder_loading') : $t('funds.deposit_fiat.submit_deposit_usd') }}
                        </button>
                        <div class="text-center my-n5">
                          <p>or</p>
                        </div>
                        <button
                          :disabled="fetch"
                          type="submit"
                          class="btn btn-submit deposit__submit-btn-qris"
                          @click="depositQr()"
                        >
                          {{ fetch ? $t('funds.withdrawals.withdrawal_placeholder_loading') : $t('funds.deposit_fiat.submit_deposit_usd_qr') }}
                        </button>
                      </div>
                    </template>
                  </div>
                  <div class="description">{{ $t('deposit_fiat.desc') }}</div>
                </div>
              </template>
            </div>
          </div>
          <div class="box_left3">
            <div class="deposit-content">
              <div class="form-group mb-0">
                <p class="txt-bold">
                  {{ $t('deposit_fiat.notes.please_note') }}
                </p>
                <div class="clearfix"></div>
                <ul class="deposit__note-wrapper">
                  <li class="txt__note-item">
                    {{ $t('deposit_fiat.notes.note_1') }}
                  </li>
                  <li class="txt__note-item">
                    {{ $t('deposit_fiat.notes.note_2') }}
                  </li>
                  <li class="txt__note-item">
                    {{ $t('deposit_fiat.notes.note_3') }}
                  </li>
                  <li class="txt__note-item">
                    {{ $t('deposit_fiat.notes.note_4') }}
                  </li>
                  <li class="txt__note-item">
                    {{ $t('deposit_fiat.notes.note_5') }}
                  </li>
                  <li class="txt__note-item">
                    {{ $t('deposit_fiat.notes.note_6') }}
                  </li>
                </ul>
                <div class="deposit__footer-wrapper">
                  <div class="footer__desc">{{ $t('chainalysis_note') }}</div>
                  <div class="footer__logo">
                    <img src="/images/withdrawal-footer-logo.svg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="alert alert-danger text-center space-top" role="alert">
          {{ $t('funds.disable_coin_msg') }}
        </div>
      </div>
      <div class="right_deposit_fiat">
        <div class="box_right1" id="table_deposit_usd">
          <template v-if="isMobile">
            <div class="deposit-history__mobile-wrapper">
              <data-list-mobile
                ref="datatable"
                :msg-empty-data="''"
                :get-data="getData"
                :isExpand="true"
                :limit="10"
                :height="30"
                @DataTable:finish="onDatatableFinish"
              >
                <template slot="header" slot-scope="props">
                  <div class="deposit-history__mobile-title">
                    <div class="mobile-title__item" data-sort-field="amount" @click="props.onSort">
                      {{ $t('deposit_fiat.history_table.amount') }}/{{
                        $t('deposit_fiat.history_table.status')
                      }}
                      <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
                    </div>
                    <div
                      class="mobile-title__item"
                      id="nowrap"
                      data-sort-field="method"
                      @click="props.onSort"
                    >
                      {{ $t('deposit_fiat.history_table.payment_method') }}
                      <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
                    </div>
                    <div class="mobile-title__item text-right">
                      {{ $t('deposit_fiat.history_table.action') }}
                    </div>
                  </div>
                </template>
                <template slot="table-empty-header">
                  <div class="deposit-history__mobile-title">
                    <div class="mobile-title__item">
                      {{ $t('deposit_fiat.history_table.amount') }}/{{
                        $t('deposit_fiat.history_table.status')
                      }}
                    </div>
                    <div class="mobile-title__item">
                      {{ $t('deposit_fiat.history_table.payment_method') }}
                    </div>
                    <div class="mobile-title__item text-right">
                      {{ $t('deposit_fiat.history_table.action') }}
                    </div>
                  </div>
                </template>
                <template slot="body" slot-scope="props">
                  <div class="deposit-history__mobile-item">
                    <div class="mobile-item__wrapper">
                      <div class="item-wrapper__title">
                        <span>{{ props.item.amount | abs | formatCurrencyAmount(null, '0') }}</span>
                        <br />
                        <template v-if="props.item.status === statusAccountDefault">
                          <strong
                            v-if="!props.item.is_card_payment && isExpiredTransaction(props.item)"
                            class="status-expired"
                          >
                            {{ $t('deposit_fiat.expired') }}
                          </strong>
                          <strong v-else class="status-pending">
                            {{ $t('common.transaction_status.pending') }}
                          </strong>
                        </template>
                        <strong
                          v-else-if="props.item.status === 'success'"
                          class="status-success"
                          :class="`status-${props.item.status}`"
                        >
                          {{ $t('common.transaction_status.success') }}
                        </strong>
                        <strong v-else class="status-cancel" :class="`status-${props.item.status}`">
                          {{ $t('common.transaction_status.cancel') }}
                        </strong>
                      </div>
                      <div class="item-wrapper__title">
                        <div v-if="props.item.is_card_payment" class="payment-method">
                          <div>{{ $t('deposit_fiat.credit_card') }}</div>
                          <div class="number">
                            <img :src="getImageCard(props.item.masked_card)" alt="no image" />
                            <span class="text-not-bold"
                              >- {{ props.item?.masked_card?.slice(0, 4) }} **
                              {{ props.item?.masked_card?.slice(-4) }}</span
                            >
                          </div>
                        </div>
                        <div v-else class="payment-method">
                          <div>{{ props.item.payment_method == 'BANK_TRANSFER'  || props.item.order_id.includes('deposit_bank_user') ? $t('deposit_fiat.bank_transfer') 
                                : props.item.payment_method == 'EWALLET' ? 'E-Wallet'
                                : props.item.payment_method == 'QR_CODE' ? 'QRIS'
                                : 'Deposit ' + props.item.status
                            }}</div>
                          <strong class="see-instruction" @click="toggleShowInstruction">{{
                            $t('deposit_fiat.see_instruction')
                          }}</strong>
                        </div>
                      </div>
                      <div class="item-wrapper__title">
                        <div
                          class="btn-group"
                          v-if="
                            props.item.status === statusAccountDefault &&
                            !props.item.is_card_payment &&
                            !isExpiredTransaction(props.item)
                          "
                        >
                          <div class="count-down">
                            <div>{{ $t('deposit_fiat.pay_within') }}</div>
                            <CountDown
                              :key="props.item.id"
                              :duration="countDuration(props.item)"
                              :autoCountDown="true"
                              :timeHour="true"
                              class="count"
                            />
                          </div>
                          <button @click="showConfirm(props.item.id)" class="button_cancel_deposit">
                            {{ $t('common.action.cancel') }}
                          </button>
                        </div>
                        <div v-else class="btn-group">
                          <button v-if="props.item.invoice_url" @click="redirectXenditInvoice(props.item.invoice_url)" 
                                  class="button_cancel_deposit" style="color: #ffffff; background-color: #010304;">
                            {{ $t('common.action.invoice') }}
                          </button>
                        </div>
                      </div>
                      <div
                        class="item-wrapper__dropdown-icon"
                        @click="props.onClickExpandItem(props)"
                      >
                        <img
                          src="/images/instant-convert/icon-dropdown.svg"
                          alt=""
                          v-if="!props.isActive"
                        />
                        <img src="/images/instant-convert/icon-dropdown2.svg" alt="" v-else />
                      </div>
                    </div>
                    <div class="mobile-item__expand-content" :class="{ active: props.isActive }">
                      <div class="expand-content__wrapper">
                        <div class="expand-content__title">
                          {{ $t('deposit_fiat.history_table.time') }}
                        </div>
                        <div class="expand-content__desc">
                          {{ props.item.created_at | YearMonthDate }}
                          {{ props.item.created_at | orderTime }}
                        </div>
                      </div>
                      <div class="expand-content__wrapper" v-if="!props.item.is_card_payment">
                        <div class="expand-content__title">
                          <span
                            >{{ $t('deposit_fiat.history_table.bank') }}/{{
                              $t('deposit_fiat.history_table.account')
                            }}</span
                          ><img
                            src="/images/icon/Question.svg"
                            alt=""
                            style="transform: translateY(-2px); padding-left: 4px"
                            v-tooltip.bottom="$t('funds.deposit_fiat.copy_account_number')"
                          />
                        </div>
                        <div class="expand-content__desc text-right">
                          {{ bankCodeToName(props.item.bank) }}
                          <br />
                          <span
                            class="text-not-bold"
                            @click="copyToClipboard(props.item.va_number)"
                            >{{ props.item.va_number }}</span
                          >
                        </div>
                        <div v-if="!props.item.bank && props.item.status == 'pending'">
                          <span v-if="props.item.invoice_url" class="btn-link" @click="redirectXenditInvoice(props.item.invoice_url)">{{ $t('common.action.pay_now') }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </data-list-mobile>
            </div>
          </template>
          <template v-else>
            <data-table3
              class="table-deposit_fiat"
              :getData="getData"
              :limit="10"
              :widthTable="'100%'"
              ref="datatable"
              @DataTable:finish="onDatatableFinish"
              :msgEmptyData="msgEmptyData"
            >
              <template slot-scope="props">
                <th
                  class="sort text-center th-time"
                  data-sort-field="created_at"
                  @click="props.echoclick"
                >
                  {{ $t('funds.deposit_fiat.transaction_history_table.time') }}
                  <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
                  <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
                </th>
                <th class="t-left sort th-amount" data-sort-field="amount" @click="props.echoclick">
                  {{ $t('funds.deposit_fiat.transaction_history_table.amount') }}
                  /
                  {{ $t('funds.deposit_fiat.transaction_history_table.status') }}
                  <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
                  <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
                </th>
                <th class="t-left sort th-method" data-sort-field="method" @click="props.echoclick">
                  {{ $t('deposit_fiat.history_table.payment_method') }}
                  <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
                  <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
                </th>
                <th class="t-left sort th-bank" data-sort-field="bank" @click="props.echoclick">
                  {{ $t('deposit_fiat.history_table.bank') }} /
                  {{ $t('deposit_fiat.history_table.account') }}
                  <i class="icon-help" v-tooltip="$t('funds.deposit_fiat.copy_account_number')"></i>
                  <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
                  <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
                </th>
                <!-- <th class="t-left sort" data-sort-field="va_number" @click="props.echoclick">
                  VA Account
                  <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
                  <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
                </th> -->
                <th class="sort th-action">
                  {{ $t('deposit_fiat.history_table.action') }}
                </th>
              </template>
              <template slot="body" slot-scope="props">
                <tr>
                  <td style="" class="text-center td-time">
                    <div class="txt-group">
                      <div class="text-not-bold">
                        {{ props.item.created_at | YearMonthDate }}
                      </div>
                      <div class="text-not-bold">
                        {{ props.item.created_at | orderTime }}
                      </div>
                    </div>
                  </td>
                  <td style="" class="text-center td-amount">
                    <div class="txt-group">
                      <div class="text-not-bold">
                        {{ props.item.amount | abs | formatCurrencyAmount('idr', '0') }}
                      </div>
                      <template v-if="props.item.status === statusAccountDefault">
                        <div
                          v-if="!props.item.is_card_payment && isExpiredTransaction(props.item)"
                          class="text-not-bold-amount status-expired"
                        >
                          {{ $t('deposit_fiat.expired') }}
                        </div>
                        <div v-else class="text-not-bold-amount status-pending">
                          {{ $t('common.transaction_status.pending') }}
                        </div>
                      </template>
                      <div
                        v-else-if="props.item.status === 'success'"
                        class="text-not-bold-amount status-success"
                        :class="`status-${props.item.status}`"
                      >
                        {{ $t('common.transaction_status.success') }}
                      </div>
                      <div
                        v-else
                        class="text-not-bold-amount status-cancel"
                        :class="`status-${props.item.status}`"
                      >
                        {{ $t('common.transaction_status.cancel') }}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div v-if="props.item.is_card_payment" class="payment-method">
                      <div>{{ $t('deposit_fiat.credit_card') }}</div>
                      <div class="number">
                        <img :src="getImageCard(props.item.masked_card)" alt="no image" />
                        <span class="text-not-bold"
                          >{{ props.item?.masked_card?.slice(0, 4) }} **
                          {{ props.item?.masked_card?.slice(-4) }}</span
                        >
                      </div>
                    </div>
                    <div v-else class="payment-method">
                      <div>{{ props.item.payment_method == 'BANK_TRANSFER' || props.item.order_id.includes('deposit_bank_user') ? $t('deposit_fiat.bank_transfer') 
                                : props.item.payment_method == 'EWALLET' ? 'E-Wallet'
                                : props.item.payment_method == 'QR_CODE' ? 'QRIS'
                                : 'Deposit ' + props.item.status
                            }}</div>
                      <span class="see-instruction" @click="toggleShowInstruction">{{
                        $t('deposit_fiat.see_instruction')
                      }}</span>
                    </div>
                  </td>
                  <td class="td-bank">
                    <div v-if="!props.item.is_card_payment && props.item.bank">
                      {{ bankCodeToName(props.item.bank) }}
                      <br />
                      <span class="text-not-bold" @click="copyToClipboard(props.item.va_number)">{{
                        props.item.va_number
                      }}</span>
                    </div>
                    <div v-if="!props.item.bank && props.item.status == 'pending'">
                      <span v-if="props.item.invoice_url" class="btn-link" @click="redirectXenditInvoice(props.item.invoice_url)">{{ $t('common.action.pay_now') }}</span>
                    </div>
                    <div v-if="!isExpiredTransaction(props.item) && props.item.qr_string && props.item.status == 'pending'">
                      <span class="btn-link" @click="handleClickShowQr(props.item.qr_string, props.item.transaction_id)">{{ $t('common.action.show_qris') }}</span>
                    </div>
                  </td>
                  <td style="" class="text-right td-code">
                    <div
                      class="btn-group"
                      v-if="
                        props.item.status === statusAccountDefault &&
                        !props.item.is_card_payment &&
                        !isExpiredTransaction(props.item)
                      "
                    >
                      <div class="count-down">
                        <span>{{ $t('deposit_fiat.pay_within') }}</span>
                        <CountDown
                          :key="props.item.id"
                          :duration="countDuration(props.item)"
                          :autoCountDown="true"
                          :timeHour="true"
                          class="count"
                        />
                      </div>
                      <button v-if="!props.item.qr_string" @click="showConfirm(props.item.id)" class="button_cancel_deposit">
                        {{ $t('common.action.cancel') }}
                      </button>
                    </div>
                    <div v-else class="btn-group">
                      <button v-if="props.item.invoice_url" @click="redirectXenditInvoice(props.item.invoice_url)" 
                              class="button_cancel_deposit" style="color: #ffffff; background-color: #010304;">
                        {{ $t('common.action.invoice') }}
                      </button>
                    </div>
                  </td>
                </tr>
              </template>
            </data-table3>
          </template>
        </div>
      </div>
    </div>
    <DepositModal
      :isShow="showBanksModal"
      :amount="removeExponent(amount)"
      @close="backToWallet"
      :pending.sync="pendingTransaction"
    />
    <InstructionModal :isShow="showInstruction" @close="toggleShowInstruction" />
    <QrisModal :isShow="showQrisModal" @close="handleCloseQrModal" :qrisString="qrisString" :qrTransactionId="qrTxId" :isQrPaymentSuccess="isQrPaymentSuccess" :size="300"/>
  </div>
</template>

<script>
import rf from '@/request/RequestFactory';
import TransactionHistory from '@/components/shared_components/funds/TransactionHistory';
import BigNumber from 'bignumber.js';
import SelectBox from '@/components/shared_components/common/SelectBox';
import PopupModal from '@/components/PopupModal.vue';
import CountDown from '@/components/shared_components/funds/common/CountDown.vue';
import DepositModal from '@/components/shared_components/funds/deposit_usd/DepositModal.vue';
import InstructionModal from '@/components/shared_components/funds/deposit_usd/InstructionModal.vue';
import moment from 'moment';
import { visaRegex, masterCardRegex, JCBRegex, americanExpressRegex } from '@/common/Utils';
import QrisModal from '@/components/shared_components/funds/deposit_usd/QrisModal.vue';
export default {
  name: 'DepositFiat',
  components: {
    TransactionHistory,
    SelectBox,
    CountDown,
    PopupModal,
    DepositModal,
    InstructionModal,
    QrisModal
  },
  props: {
    success: {
      type: String,
      default: '',
    },
  },
  watch: {
    bank_name: {
      handler(newValue) {
        switch (newValue) {
          case 0: {
            this.virtual_account_number = '1324922275113621';
            break;
          }
          case 1: {
            this.virtual_account_number = '1554922275113622';
            break;
          }
          case 2: {
            this.virtual_account_number = '1674922275113733';
            break;
          }
          case 3: {
            this.virtual_account_number = '1774922275113555';
            break;
          }
        }
      },
    },
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      virtual_account_number: '1324922275113621',
      bank_name: 0,
      bankOptions: [
        { id: 0, name: 'BCA', code: 'bca', accountNumber: '' },
        // { id: 1, name: "Echannel", code: 'echannel', accountNumber: "" },
        { id: 2, name: 'BNI', code: 'bni', accountNumber: '' },
        { id: 3, name: 'Permata', code: 'permata', accountNumber: '' },
        { id: 4, name: 'BRI', code: 'bri', accountNumber: '' },
      ],
      statusAccountDefault: 'pending',
      currencyName: 'idr',
      balance: {},
      amount: '',
      pendingTransaction: {},
      isLoading: false,
      msgEmptyData: this.$i18n.t('funds.depositUSD.no_data'),
      color: '',

      isLoaded: false,
      isDepositEnable: false,
      totalInOrder: 0,
      showBanksModal: false,
      showInstruction: false,
      showQrisModal: false,
      maximumDeposit: 0,
      minimumDeposit: 0,
      isShowDetails: false,
      isQrPaymentSuccess: false,

      invoiceUrl: '',
      fetch: true,
      qrisString: '',
      qrTxId: '',
    };
  },

  computed: {
    hasPendingTransaction() {
      return !this.isEmpty(this.pendingTransaction);
    },
    isMobile() {
      if (!this.windowWidth) return;
      return this.windowWidth <= 768;
    },
  },

  methods: {
    getSettingDepositLimit(useCache = true) {
      rf.getRequest('UserRequest')
        .getCurrentUser(useCache)
        .then((res) => {
          const securityLevel = res.data.security_level;
          let depositLimit = {};

          rf.getRequest('UserRequest')
            .getDepositLimit()
            .then((res) => {
              depositLimit =
                res.data.find((dl) => {
                  return dl.security_level === securityLevel;
                }) || {};

              this.maximumDeposit = depositLimit.limit;
              this.minimumDeposit = depositLimit.minimum_deposit;
            });
        });
    },
    onDatatableFinish() {
      this.fetch = false;
    },
    getImageCard(number) {
      const cardNumber = number.replace(/\s+/g, '');
      if (!cardNumber) return '/images/icon/master_card.png';
      if (cardNumber.match(visaRegex)) return '/images/icon/visa.png';
      if (cardNumber.match(masterCardRegex)) return '/images/icon/master_card.png';
      if (cardNumber.match(JCBRegex)) return '/images/icon/jcb.jpg';
      if (cardNumber.match(americanExpressRegex)) return '/images/icon/american_express.png';
      return '/images/icon/master_card.png';
    },
    isExpiredTransaction(item) {
      let time = item.expiry_time;
      if (!time) time = item.created_at + 86400000;
      if (moment(time).isBefore(moment())) {
        return true;
      }
      return false;
    },
    countDuration(item) {
      let time = item.expiry_time;
      if (!time) time = item.created_at + 86400000;
      const date = moment(time);
      const timestamp = moment(date).format('X');
      const now = Date.now() / 1000;
      return Number((timestamp - now).toFixed(0));
    },
    toggleShowInstruction() {
      this.showInstruction = !this.showInstruction;
    },
    bankCodeToName(code) {
      switch (code) {
        case 'bca':
          return 'BCA';
        case 'echannel':
          return 'Echannel';
        case 'bni':
          return 'BNI';
        case 'permata':
          return 'Permata';
        case 'PERMATA':
          return 'Permata';
        case 'bri':
          return 'BRI';
        case 'bsi':
          return 'BSI';
        case 'mandiri':
          return 'Mandiri';
        case 'bjb':
          return 'BJB';
        case 'astrapay':
          return 'AstraPay';
        case 'dana':
          return 'Dana';
        case 'qris':
          return 'QRIS';
        default:
          return '';
      }
    },
    bankCodeToFullName(code) {
      switch (code) {
        case 'bca':
          return 'PT. BANK CENTRAL ASIA TBK.';
        case 'echannel':
          return 'Echannel';
        case 'bni':
          return 'PT. BANK NEGARA INDONESIA (PERSERO)';
        case 'permata':
          return 'PT. BANK PERMATA TBK.';
        case 'bri':
          return 'PT. BANK RAKYAT INDONESIA (PERSERO)';
        default:
          return '';
      }
    },
    resetError() {
      this.errors.clear();
      this.color = '';
    },
    validateDepositWithdraw() {
      rf.getRequest('MasterdataRequest')
        .getAll()
        .then((res) => {
          const setting = window._.filter(res.coins_confirmation, (item) => item.coin === 'idr');
          if (!window._.isEmpty(setting)) {
            this.isDepositEnable = setting[0].is_deposit;
          }
          this.isLoaded = true;
        })
        .catch((e) => {
          this.isLoaded = true;
        });
    },
    isEmpty(val) {
      return _.isEmpty(val);
    },

    getUsdBalance() {
      this.balance = {};
      rf.getRequest('UserRequest')
        .getDetailsUsdBalance('idr')
        .then((res) => {
          this.balance = {
            balance: new BigNumber(`${res.data.main.balance || 0}`).add(
              `${res.data.exchange.balance || 0}`
            ),
            available_balance: new BigNumber(`${res.data.main.available_balance || 0}`),
            in_order: new BigNumber(`${res.data.exchange.balance || 0}`).minus(
              `${res.data.exchange.available_balance || 0}`
            ),
            pending_withdrawal: new BigNumber(`${res.data.main.balance || 0}`).minus(
              `${res.data.main.available_balance || 0}`
            ),
            tradable_balance: new BigNumber(`${res.data.exchange.available_balance || 0}`),
            total_locked_balance: new BigNumber(`${res.data.exchange.balance || 0}`)
              .minus(`${res.data.exchange.available_balance || 0}`)
              .add(`${res.data.main.balance || 0}`)
              .minus(`${res.data.main.available_balance || 0}`)
              .add(`${res.data.exchange.available_balance}`),
          };
        });
    },

    calculateBalanceInOrder() {
      return rf
        .getRequest('TransactionRequest')
        .getTotalUsdPendingWithdraw()
        .then((res) => {
          const totalUsdPendingWithdraw = res.data.total || '0';
          const inOrder = new BigNumber(`${this.totalInOrder || 0}`)
            .add(`${totalUsdPendingWithdraw}`)
            .toString();
          this.$set(this.balance, 'in_order', inOrder);
        });
    },

    getData(params) {
      const meta = {
        type: 'deposit',
      };
      return rf
        .getRequest('TransactionRequest')
        .getUsdTransactions(Object.assign({}, params, meta))
    },

    onBalanceUpdated(newBalance) {
      this.getUsdBalance();

      if (this.showQrisModal) { // ON SUCCESS QRIS PAYMENT
        this.onSuccessQrPayment();
      }
    },

    formatMoney(val) {
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },

    validate() {
      this.errors.clear();

      if (this.amount === '' || this.amount === undefined) {
        this.errors.add('amount', 'funds.deposit_fiat.errors.required');
      }
      if (new BigNumber(`${this.amount}`).lt(this.minimumDeposit)) {
        console.log(this.minimumDeposit);
        this.errors.add(
          'amount',
          this.$i18n.t('funds.deposit_fiat.errors.min_amount', {
            min_amount: this.formatMoney(this.minimumDeposit),
          })
        );
      }
      if (new BigNumber(`${this.amount}`).gt(this.maximumDeposit)) {
        console.log(this.maximumDeposit);
        this.errors.add(
          'amount',
          this.$i18n.t('funds.deposit_fiat.errors.max_amount', {
            max_amount: this.formatMoney(this.maximumDeposit.split('.')[0]),
          })
        );
      }
      // if (new BigNumber(`${this.amount || 0}`).toNumber() > 0) {
      //   this.errors.add('amount', this.$i18n.t('messages.error.balance_insufficient'));
      // }

      if (this.errors.count() > 0) {
        this.color = '#e2221e';
        return false;
      }
      return true;
    },
    showConfirm(data) {
      ConfirmationModal.show({
        type: 'warning-2',
        title: '',
        widthModal: '370px',
        content: this.$i18n.t('funds.deposit_fiat.transaction_history_table.text1'),
        btnCancelLabel: this.$i18n.t('common.action.no'),
        btnConfirmLabel: this.$i18n.t('common.action.yes'),
        onConfirm: () => {
          this.cancel(data);
          this.fullscreen = false;
        },
        onCancel: () => {},
      });
    },

    cancel(transactionId) {
      rf.getRequest('TransactionRequest')
        .cancelUsdDepositTransactionInvoice(transactionId)
        .then((res) => {
          (this.amount = ''), (this.pendingTransaction = {});
          this.$refs.datatable.refresh();
          Message.success(this.$i18n.t('address.cancel_success'), {}, { position: 'top_right' });
        })
        .catch(function (error) {
          Message.error('Canceled failed', {}, { position: 'top_right' });
        });
    },

    deposit() {
      if (!this.validate()) {
        return false;
      }
      // this.showBanksModal = true; //<<< this is for midtrans
      const amount = parseFloat(this.amount);

      this.isLoading = true;
      rf.getRequest('TransactionRequest')
        .depositUsdInvoice({amount})
        .then((res) => {
          this.redirectXenditInvoice(res.data.invoice_url);
        })
        .catch((error) => {
          console.log("ERROR: ", error)
        })
        .finally(() => {
          this.isLoading = false;
          this.amount = '';
          this.$refs.datatable.refresh();
        })
    },

    depositQr() {
      this.isQrPaymentSuccess = false;
      if (!this.validate()) {
        return false;
      }

      const amount = parseFloat(this.amount);

      this.isLoading = true;
      rf.getRequest('TransactionRequest')
        .depositUsdQris({amount})
        .then((res) => {
          console.log(res, "RESPONSE DEPOSIT QR")
          this.qrisString = res.data.qr_string
          this.qrTxId = res.data.transaction_id
          this.showQrisModal = true
        })
        .catch((error) => {
          console.log("ERROR: ", error)
        })
        .finally(() => {
          this.isLoading = false;
          this.amount = '';
          this.$refs.datatable.refresh();
        })
      // this.qrisString = "rizzzz"
      // this.showQrisModal = true
    },

    onSuccessQrPayment() {
      this.isQrPaymentSuccess = true
    },

    handleClickShowQr(qrString, qrTxId) {
      this.isQrPaymentSuccess = false
      this.qrisString = qrString
      this.qrTxId = qrTxId
      this.showQrisModal = true
    },

    redirectXenditInvoice(url) {
      window.open(url, '_blank');
    },

    createOtherDeposit() {
      this.color = '#cfcfcf';
      this.pendingTransaction = {};
    },

    onTransactionCreated() {
      if (this.isLoading) {
        return;
      }
      this.$refs.datatable.refresh();
    },

    getSocketEventHandlers() {
      return {
        TransactionCreated: this.onTransactionCreated,
        MainBalanceUpdated: this.onBalanceUpdated,
        BalanceUpdated: this.onBalanceUpdated,
      };
    },
    copyToClipboard(content) {
      if (!navigator.clipboard) {
        Utils.fallbackCopyTextToClipboard(content);
        return;
      }
      navigator.clipboard.writeText(content);

      Message.success(
        this.$i18n.t('funds.deposit_fiat.copy_success_message'),
        {},
        { position: 'top_right' }
      );
    },
    backToWallet() {
      this.showBanksModal = false;
      this.amount = '';
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    removeExponent: function (data) {
      var data = String(data).split(/[eE]/);
      if (data.length == 1) return data[0];

      var z = '',
        sign = this < 0 ? '-' : '',
        str = data[0].replace('.', ''),
        mag = Number(data[1]) + 1;

      if (mag < 0) {
        z = sign + '0.';
        while (mag++) z += '0';
        return z + str.replace(/^\-/, '');
      }
      mag -= str.length;
      while (mag--) z += '0';
      return str + z;
    },
    handleCloseQrModal() {
      this.showQrisModal = false;
      this.qrisString = "";
      this.qrTxId = "";
      this.isQrPaymentSuccess = false;
    }
  },
  beforeDestroy() {
    window.removeEventListener('focus', (event) => {
      this.color = '#2dac91';
      this.errors.clear();
    });
    window.removeEventListener('resize', this.onResize);
  },
  mounted() {
    this.getSettingDepositLimit();
    window.addEventListener('focus', (event) => {
      this.color = '#2dac91';
      this.errors.clear();
    });
    this.getUsdBalance();
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  created() {
    document.title = this.$t('menu.deposit_usd') + ` – ${this.tabTitle}`;
    this.validateDepositWithdraw();
    this.$on('UPDATED_LOCALE', () => {
      this.msgEmptyData = this.$i18n.t('funds.depositUSD.no_data');
    });
  },
};
</script>

<style lang="scss">
.base_deposit_withdraw {
  .multiselect__tags .multiselect__input {
    border: none !important;
  }
}
</style>

<style lang="scss" scoped>
.deposit__note-wrapper {
  //li::before {
  //content: "•";
  //color: #666666;
  //font-weight: bold;
  //display: inline-block;
  //width: 20px;
  //margin-left: -20px;
  //}
  list-style-type: disc;

  .txt__note-item {
    color: $text-secondary;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    margin-left: 18px;
    margin-bottom: 8px;

    @include mobile {
      font-size: 14px;
      line-height: 22px;
    }
  }
}

.clearfix::after {
  display: block;
  clear: both;
  content: '';
}

.form-input {
  // background: $color-bright-gray;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10.5px 16px;
  gap: 10px;
  width: 274px;
  height: 41px;
  border-radius: 12px;

  &:focus {
    border: 1px solid $bg-main1 !important;
  }
}

.deposit__footer-wrapper {
  display: flex;
  gap: 16px;
  margin-top: 16px;
  width: 100%;
  flex-direction: column;

  .footer__desc {
    color: $text-main-blur;
    font-size: 14px;
    font-weight: 300;
    line-height: 22px;
  }

  .footer__logo {
    display: flex;
    width: 130px;
    height: 71px;

    img {
      // width: 100%;
      height: auto;
    }
  }
}

.container_deposit_fiat {
  padding: 24px;
  max-width: 1370px;
  margin: auto;
  background-color: $background-white-smoke;

  @include mobile {
    padding: 24px 16px;
  }

  .title-medium-small {
    margin: 0;
    // height: 30px;
    color: $text-main;
    font-size: 24px;
    font-weight: 700;
    line-height: 40px;

    @include mobile {
      font-size: 18px;
      line-height: 32px;
    }
  }

  .deposit_fiat {
    display: grid;
    grid-template-columns: 45% calc(55% - 24px);
    gap: 24px;
    margin-top: 24px;

    @include tablet {
      grid-template-columns: 1fr;
    }

    @include mobile {
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    & > * {
      flex: 1;

      // overflow: hidden;
      @media (max-width: 1024px) {
        overflow: hidden;
      }
    }

    .left_deposit_fiat {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 24px 30px;

      .list-balance {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .content-item-balance {
          background-color: #f8f8f8;
          padding: 16px;
          border-radius: 7px;
        }

        .item-balance {
          display: flex;
          justify-content: space-between;
          margin-bottom: 8px;
          word-break: break-word;

          span {
            color: $text-secondary;
            font-size: 16px;
            font-weight: 300;
            line-height: 24px;
          }

          strong {
            color: $text-main;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            text-align: right;
          }
        }
      }

      .box-left-group {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }

      .box_left2 {
        .box_left2-content {
          .left-content-submit {
            // margin-right: 30px;
            // margin-left: 30px;
            // padding-top: 25px;
            // padding-bottom: 33px;
            // display:flex;
            // align-items: center;
            // gap:14px;
            .address-box {
              margin-bottom: 15px;
              display: grid;
              grid-template-columns: auto 120px;
              gap: 16px;

              @include mobile {
                grid-template-columns: auto 96px;
                // align-items: center;
              }

              input {
                width: 100%;
              }

              .error {
                border-color: #ff5b5b;
                background: rgba(255, 91, 91, 0.1);
              }
            }

            // .input-group {
            // display:flex;
            // flex-direction: column;
            // gap: 9px;
            // }
            .address_for {
              margin-bottom: 8px;
              color: $text-secondary;
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;

              @include mobile {
                font-size: 14px;
              }
            }

            input {
              height: 41px;
              // border-radius: 3px;
              border: solid 1px $border-gray;
              // background-color: $background-bright-gray;
              border-radius: 12px;
            }

            input:focus,
            input:active {
              border: solid 1px $border-green;
              color: $text-default;
            }
          }

          .left-content-result {
            // margin-right: 30px;
            // margin-left: 30px;
            // padding-top: 25px;
            // padding-bottom: 33px;
            background-color: white;
            // min-height: 330px;
            border-radius: 10px;
            padding: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 15px;

            p {
              margin-bottom: 0px;
            }

            .pending_deposit_transaction_table {
              width: 100%;
              height: 120px;
              border-collapse: separate;
              border-spacing: 0 8px;

              .title_row {
                color: $text-secondary;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                vertical-align: top;
                min-width: 110px;
              }

              .content_row {
                color: $text-main;
                font-size: 16px;
                font-weight: 700;
                line-height: 24px;
                text-align: right;
                word-break: break-word;
              }

              @include mobile() {
                .title_row {
                  font-size: 14px;
                  line-height: 22px;
                  text-transform: capitalize;
                }

                .content_row {
                  font-size: 14px;
                  line-height: 22px;
                }
              }
            }

            .first_deposit_table {
              color: $color-red;
              font-size: 14px;
              font-weight: 700;
              line-height: 24px;

              @include mobile {
                font-size: 12px;
                line-height: 16px;
              }
            }

            .content_row {
              text-align: right;
            }
          }
        }

        button {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: flex-start;
          padding: 9px 32px;
          gap: 10px;
          width: 120px;
          height: 42px;
          border-radius: 60px;
          background-color: $bg-main6 !important;
          border: none;
          z-index: 0;

          color: $color-white;
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          text-transform: capitalize;

          @include mobile {
            font-size: 14px;
            padding: 7px 24px;
            height: 38px;
            width: 96px;
            margin: 0;
          }
        }

        // button:hover, button:active, button:focus{
        //   background: $background-blue-custom;
        //   border-color: $background-blue-custom;
        //   color: $color-white;
        // }
        .flex-button {
          margin: 0;
        }

        .flex_button_box {
          .w_100 {
            width: 100%;
          }
        }
      }

      .description {
        color: $text-main;
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;

        @include mobile {
          font-size: 12px;
          line-height: 16px;
        }
      }

      .box_left3 {
        p {
          @include text-font($helvetica, $dark-3, $font-medium-bigger, 500, 27px);
        }

        .txt-bold {
          color: $text-main;
          font-size: 18px;
          font-weight: 700;
          line-height: 32px;
          margin-bottom: 16px;

          @include mobile {
            font-size: 16px;
            line-height: 24px;
          }
        }

        .txt-tit {
          @include text-font($helvetica, $dark-1, $font-big, 700, 23px);

          & + p {
            margin-bottom: 24px;
          }
        }

        .guide-notice {
          @include text-font($helvetica, $primary-2, $font-root, 700, 18px);
        }
      }
    }

    .table-container {
      background: $color-white;
      border-radius: 10px;
    }

    .center_deposit_fiat {
      width: 30px;
    }

    .right_deposit_fiat {
      .table-deposit_fiat {
        // padding: 23px;
        height: 100%;
        background-color: $background-default;
        border-radius: 10px;
      }

      table {
        thead {
          th {
            height: 40px;
            background: #f8f8f8;
            border: none;
          }
        }

        // tbody::before {
        //   content:"\200C";
        //   display: table-row;
        //   line-height: calc(15px - 4px);
        //   z-index: -99999px;
        // }
        & > * > * > * {
          padding: 7.5px 4px;

          &:first-child {
            padding-left: 22px;
          }

          &:last-child {
            padding-right: 20px;
          }
        }

        tr {
          &:hover {
            background-color: $color-bright-gray;
          }
        }

        th {
          padding-top: 8px;
          padding-bottom: 8px;
          color: $text-secondary;
          font-size: 14px;
          font-weight: 700;
          line-height: 24px;
          border: none;
          font-family: $helvetica;

          &:first-child {
            border-top-left-radius: 10px;
            padding-left: 22px;
          }

          &:last-child {
            border-top-right-radius: 10px;
            padding-right: 20px;
          }

          &.after_none:after {
            display: none;
          }

          background-color: $background-default;
        }

        td {
          // .text-bold {
          //   @include text-font($helvetica, $dark-1, $font-root, 700, 18px);
          // }
          font-family: $helvetica;

          .text-not-bold {
            color: $text-main;
            font-size: 16px;
            font-weight: 300;
            line-height: 24px;
          }

          .txt-btn-group,
          .txt-group {
            display: flex;
            flex-direction: column;
            // gap: 5px;
          }

          .payment-method {
            color: $text-main;
            font-size: 16px;
            font-weight: 300;
            line-height: 24px;

            .see-instruction {
              font-weight: 700;
              cursor: pointer;
            }

            .number {
              display: flex;
              align-items: center;
              gap: 4px;

              img {
                width: 24px;
                height: 24px;
              }

              span {
                white-space: nowrap;
              }
            }
          }

          .btn-group {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            .count-down {
              display: flex;
              gap: 5px;
              color: $text-main;
              font-size: 12px;
              font-weight: 300;
              line-height: 16px;
              white-space: nowrap;
            }

            .button_cancel_deposit {
              font-family: $helvetica;
              display: flex;
              width: 94px;
              height: 38px;
              padding: 7px 24px;
              align-items: flex-end;
              gap: 10px;
              border-radius: 60px;
              background: $color-light-grey;
              border: none;
              margin-top: 14px;
              color: $text-main;
              font-size: 14px;
              font-weight: 700;
              line-height: 24px;
            }
          }

          button {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-start;
            padding: 7px 20px;
            gap: 10px;
            width: 86px;
            height: 32px;
            border: 1px solid $primary-2;
            border-radius: 30px;
            background-color: transparent;
            margin: 0;
            @include text-font($helvetica, $primary-2, $font-root, 800, 18px);
          }

          // button:focus, button:hover, button:active {
          //   background: $background-blue-custom;
          //   border-color: $border-blue-custom;
          //   color: $color-white;
          // }
        }
      }

      .th-time,
      .td-time,
      .th-amount,
      .td-amount,
      .td-account {
        text-align: left;
      }

      .th-code,
      .td-code {
        text-align: right;
      }

      .th-time {
        min-width: 120px;
      }

      .th-amount {
        min-width: 140px;
      }

      .th-method {
        min-width: 140px;
      }

      .th-bank {
        min-width: 165px;

        .icon-help {
          cursor: pointer;
          margin-top: -10px;
        }
      }

      .td-bank {
        color: $text-main;
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;

        span {
          cursor: pointer;
        }
      }

      .th-code {
        min-width: 110px;
      }

      .th-action {
        text-align: right;
      }

      .text-bold-account {
        text-overflow: ellipsis;
        width: 200px;
        overflow: hidden;
      }

      .text-not-bold-account {
        color: $text-grey-dusty;
        text-overflow: ellipsis;
        width: 200px;
        overflow: hidden;
      }

      .text-not-bold-amount {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
      }

      .status-success {
        color: $color-green !important;
      }

      .status-pending {
        color: $color-yellow !important;
      }

      .status-cancel {
        color: $text-main-blur !important;
      }

      .status-expired {
        color: $color-red !important;
      }

      .status-submitted {
        color: $color-dark-green;
      }

      .status-canceled {
        color: $color-denim;
      }

      .status-error,
      .status-cancel,
      .status-rejected {
        color: $color-denim;
      }
    }
  }

  .banks-modal {
    max-width: 600px;
    height: auto;
    margin: 0 auto;
    background: white;
    border-radius: 20px;
    overflow: hidden;

    .modal-header {
      padding: 15px;
      text-align: center;
      background: $primary-2;
    }

    .modal-body {
      padding: 20px;

      .amount {
        border: 1px solid $border-gray;
        border-radius: 10px;
        padding: 5px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .count-down {
          color: $primary-0;
        }
      }

      .bank-list {
        margin-top: 30px;

        .head-tit {
          font-size: 20px;
          line-height: 24px;
          color: #13273b;
          font-weight: 600;
        }

        .bank-item {
          width: 100%;
          padding: 18px;
          background: #f2f3f5;
          border-radius: 10px;
          margin-top: 15px;
          display: grid;
          align-items: center;
          grid-template-columns: 50px auto 20px;
          gap: 10px;
          cursor: pointer;

          .bank-name {
            font-weight: 600;
            color: #13273b;
          }

          .bank-description {
            line-height: 1;
          }
        }
      }

      .bank-detail {
        margin-top: 30px;
        font-size: 16px;
        line-height: 20px;

        .bank-name {
          font-weight: 600;
        }

        .description {
          margin-top: 30px;
        }

        .account-number {
          margin-top: 30px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .copy {
            cursor: pointer;
          }
        }

        .tips {
          margin-top: 30px;
          color: $primary-2;
          cursor: pointer;
        }

        .btn-back {
          background: $primary-2;
          border-radius: 10px;
          font-size: 20px;
          color: white;
          padding: 10px 30px;
          display: flex;
          justify-content: center;
          margin: 30px auto 0 auto;
        }
      }
    }
  }
}

.btn-isLoading {
  background: $background-blue-custom !important;
  border-color: $border-blue-custom !important;
  color: $color-white !important;
}

.space-top {
  margin-top: 20px;
}

.item_email_user {
  display: block;
  position: relative;

  .txt_email_user {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .tooltip_email_user {
    position: absolute;
    top: -10px;
    left: 20px;
    line-height: 20px;
    padding: 5px 20px;
    // left: 100%;
    background-color: $color_white;
    white-space: nowrap;
    width: 350px !important;
    z-index: 100;
    font-size: $font_root;
    font-weight: 500;
    color: $color-grey-dark;
    transition: 0.5s;
    display: none !important;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4);

    span {
      word-wrap: break-word;
      white-space: normal;
    }

    &:after {
      right: 100%;
      top: 30px;
      border: solid transparent;
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(136, 183, 213, 0);
      border-right-color: $color_white;
      border-width: 5px;
      margin-top: -5px;
    }
  }

  &:hover {
    .tooltip_email_user {
      display: block !important;
      transition: 0.5s;
    }
  }
}

td,
.td-account {
  overflow: unset;
}

@include mobile {
  .form-input {
    width: 100%;
  }

  .input-group {
    width: 100%;
  }

  .container_deposit_fiat {
    padding: 24px 16px;

    .title-medium-small {
      font-size: 18pxpx;
    }

    .deposit_fiat {
      .left_deposit_fiat {
        padding: 24px 16px;

        .list-balance {
          .item-balance {
            margin-bottom: 6px;

            span {
              font-size: 14px;
              line-height: 22px;
              font-weight: 300;
            }

            strong {
              font-size: 14px;
              line-height: 24px;
            }
          }
        }

        .box_left2 {
          .box_left2-content {
            .left-content-submit {
              .address-box {
                flex-direction: column;
              }

              input {
                min-width: 100%;
                border-radius: 8px;
                padding: 9px 16px;
                height: 42px;
                border-color: #ddd;
                font-size: 16px;
                font-weight: 300;
                line-height: 24px;
              }
            }
          }

          .flex-button {
            width: 100%;
          }

          .flex_button_box {
            margin-top: 2px;
            width: 100%;
          }
        }

        .box_left3 {
          .txt-bold {
            font-size: 16px;
          }

          .txt-tit {
            font-size: 16px;
          }

          p {
            font-size: 12px;
          }

          .guide-notice {
            font-size: 12px;
          }
        }
      }
    }
  }
}

.address-box__multiple-input {
  display: flex;
  gap: 14px;

  @media (max-width: 767px) {
    display: block;
  }
}

.deposit__submit-btn.btn.btn-submit {
  padding: 10px 30px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
}
</style>

<style lang="scss">
.deposit__custom-selectbox.sc_search_select .group_search_select div.button_drop_search {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10.5px 16px;
  gap: 10px;
  width: 274px;
  height: 41px;
  border-radius: 12px;

  &:focus {
    border: 1px solid $bg-main1 !important;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
}

#table_deposit_usd {
  height: 100%;

  .tableContainer {
    width: 100%;
    overflow: auto;

    @include mobile {
      position: relative;

      > :nth-child(2) {
        position: sticky;
        left: 0;
        width: fit-content;
      }
    }
  }

  .VuePagination {
    width: 100%;
    background-color: $color-white;
  }
}

#funds_deposit_usd {
  &.container_deposit_fiat {
    .deposit_fiat {
      .right_deposit_fiat {
        .th-code {
          padding: 8px 0px 8px 0px;
        }
      }
    }
  }

  .deposit-history__mobile-wrapper {
    border-radius: 10px;
    overflow: hidden;

    .data-list__wrapper {
      .deposit-history__mobile-title {
        display: grid;
        border-radius: 10px 10px 0px 0px;
        background-color: $bg-main4;
        padding: 8px 16px;
        grid-template-columns: repeat(3, 1fr);

        .mobile-title__item {
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 16px;
          color: $text-secondary;

          &.text-right {
            text-align: right;
            padding-right: 30px;
          }

          &#nowrap {
            display: flex;
            white-space: nowrap;
          }
        }
      }

      .deposit-history__mobile-item {
        background-color: $color-white;
        padding: 10px 16px;
        border-bottom: 1px solid $bg-main2;

        .mobile-item__wrapper {
          display: grid;
          position: relative;
          grid-template-columns: repeat(3, 1fr);

          .item-wrapper__title {
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 22px;
            color: $text-main;

            strong {
              line-height: 24px;
            }

            .payment-method {
              color: $text-main;
              font-size: 14px;
              font-weight: 300;
              line-height: 22px;

              .see-instruction {
                font-weight: 700;
                cursor: pointer;
                line-height: 24px;
              }

              .number {
                display: flex;
                align-items: center;
                gap: 4px;

                img {
                  width: 24px;
                  height: 24px;
                }

                span {
                  white-space: nowrap;
                }
              }
            }

            .btn-group {
              width: calc(100% - 30px);
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              gap: 8px;
            }

            .count-down {
              font-size: 12px;
              font-weight: 300;
              line-height: 16px;
              text-align: right;
            }

            .button_cancel_deposit {
              font-family: $helvetica;
              display: flex;
              width: 94px;
              height: 38px;
              padding: 7px 24px;
              align-items: flex-end;
              gap: 10px;
              border-radius: 60px;
              background: $color-light-grey;
              border: none;
              margin-right: 0;
              color: $text-main;
              font-size: 14px;
              font-weight: 700;
              line-height: 24px;

              @include mobile {
                padding: 6px 16px;
                height: 36px;
                width: 78px;
              }
            }
          }

          .item-wrapper__dropdown-icon {
            position: absolute;
            right: 0px;
          }
        }

        .mobile-item__expand-content {
          display: none;

          &.active {
            display: flex;
          }

          margin-top: 12px;
          background-color: $bg-main4;
          padding: 16px;
          flex-direction: column;
          border-radius: 8px;
          gap: 8px;

          .expand-content__wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .expand-content__title {
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 24px;
              color: $text-main;
            }

            .expand-content__desc {
              font-size: 14px;
              font-style: normal;
              font-weight: 300;
              line-height: 22px;
              color: $text-main;
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              word-wrap: break-word;
            }
          }

          hr {
            margin: 10px 0px;
            border-color: #ddd;
          }
        }
      }
    }
  }

  @include mobile() {
    padding-bottom: 20px;

    .tableContainer {
      min-height: 0;
    }

    .empty-noti {
      min-height: 0;
    }

    .deposit_fiat {
      flex-direction: column;

      .left_deposit_fiat {
        width: 100%;

        .list-balance {
          .item-balance {
            strong {
              text-align: start;
            }
          }
        }
      }

      .right_deposit_fiat {
        width: 100%;
      }
    }

    #table_deposit_usd .VuePagination {
      display: flex;
    }

    .group-tooltip {
      .box-tooltip {
        left: 0;
        top: calc(100% + 0.75rem);
        translate: none;

        &::before {
          left: 0.25rem;
          translate: none;
        }
      }
    }
  }

  @include tablet() {
    .tableContainer {
      min-height: 0;
    }

    .empty-noti {
      min-height: 0;
      height: unset;
    }

    .deposit_fiat {
      flex-direction: column;

      .left_deposit_fiat {
        width: 100%;
      }

      .right_deposit_fiat {
        width: 100%;
      }
    }
  }
}

.tooltip-container {
  display: flex;
  column-gap: 5px;
}

.group-tooltip {
  color: $color-red;

  display: flex;
  align-items: center;
  gap: 5px;

  .help-box {
    position: relative;
    img {
      cursor: pointer;
    }
    + span {
      white-space: nowrap;
    }
  }
  .box-tooltip {
    &::before {
      content: '';
      position: absolute;
      left: 50%;
      bottom: 100%;
      translate: -50%;
      border: 5px solid transparent;
      border-bottom-color: #010304;
    }
    width: 230px;
    position: absolute;
    display: none;
    z-index: 999999;
    text-align: center;
    background: #010304;
    left: 50%;
    top: calc(100% + 0.75rem);
    translate: -50%;
    padding: 8px 10px;
    border-radius: 4px;

    .locked-balance-text {
      color: #fff;
      font-weight: 400;
      text-align: left;
      letter-spacing: 0.2px;
      font-size: 12px !important;
      line-height: 16px !important;
    }
  }
  &:hover .box-tooltip {
    display: block;
  }
}
</style>
